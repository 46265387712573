import React from "react";
import "../css/appinfo.css";

function AppInfo() {
  return (
    <div className="appinfo-wrapper" data-aos="fade-up">
      <div className="appinfo-box">
        <p className="count"> 15k +</p>
        <p className="specific-info">Pincodes served</p>
      </div>
      <div className="appinfo-box">
        <p className="count">1 Lac +</p>
        <p className="specific-info">Happy Customers</p>
      </div>
      <div className="appinfo-box">
        <p className="count">50k +</p>
        <p className="specific-info">Active Agents</p>
      </div>
      <div className="appinfo-box">
        <p className="count">1 Lac +</p>
        <p className="specific-info">Downloads</p>
      </div>
    </div>
  );
}

export default AppInfo;
