import React from "react";
import Slider from "react-slick";
import "../css/stepsmobile.css";
import "../css/slick.css";
import "../css/slick-theme.css";
import img1 from "../images/slideImages/ss1.png";
import img2 from "../images/slideImages/ss2.png";
import img3 from "../images/slideImages/ss3.png";
import img4 from "../images/slideImages/ss4.png";

function StepsMobile() {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3500,
    cssEase: "linear",
  };
  return (
    <div className="steps-container">
      <p id="offers-heading"> <span className="blue">Simple</span> Application Process</p>
      <p id="subheading">(Follow 4 simple steps)</p>
      <Slider {...settings}>
        <div className="steps-box">
          <div className="stepnumber">
            <p>1</p>
          </div>
          <div className="stepinfo">
            <p> Choose a  financial product to sell</p>
          </div>
          <div className="stepimg">
            <img src={img1} alt="" />
          </div>
        </div>
        <div className="steps-box">
          <div className="stepnumber">
            <p>2</p>
          </div>
          <div className="stepinfo">
            <p>  Share links with your customers</p>
          </div>
          <div className="stepimg">
            <img src={img2} alt="" />
          </div>
        </div>
        <div className="steps-box">
          <div className="stepnumber">
            <p>3</p>
          </div>
          <div className="stepinfo">
            <p> Help customers in completing the application process</p>
          </div>
          <div className="stepimg">
            <img src={img3} alt="" />
          </div>
        </div>
        <div className="steps-box">
          <div className="stepnumber">
            <p>4</p>
          </div>
          <div className="stepinfo">
            <p>Get your payouts directly in your account</p>
          </div>
          <div className="stepimg">
            <img src={img4} alt="" />
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default StepsMobile;
