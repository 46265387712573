import React from "react";
import Slider from "react-slick";
import star from "../images/golden_star.png";
import janmejay from "../images/janmejay.jpg";
import anish from "../images/anish.jpg";
import rajeev from "../images/rajeev.png";
import sandeep from "../images/sandeep.jpg";
import review_img from '../images/review_image.png'
import "../css/reviews.css";
import "../css/slick.css";
import "../css/slick-theme.css";

function Reviews() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 700,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  return (
    <div className="reviews-wrapper">
      <p id="reviews-heading">Our <span className="blue">Happy Users!</span></p>
      <div className="reviews-container">
        <div className="reviewers-logo">
          <img src={review_img} alt="" />
        </div>
        <div className="review-container">
          <Slider {...settings}>
            <div className="review-box">
              <div className="rating-wrapper">
                <div className="user-box">
                  <img src={sandeep} alt="" id="happy_user"  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  <p className="user_name">Sandeep Sharma</p>
                </div>
                <div id="rating-box">
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                </div>
              </div>
              <br />
              <div className="review-data">
                <p>
                It's really good earning app where your leads get tracked in 2 days & payouts get released on time every week. I have been associated with ARYO for the past 2 years and really appreciate the work that ARYO team is doing!
                </p>
              </div>
            </div>
            <div className="review-box">
              <div className="rating-wrapper">
                <div className="user-box">
                  <img src={janmejay} alt="" id="happy_user" />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  <p className="user_name">Janmejay Singh</p>
                </div>
                <div id="rating-box">
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                </div>
              </div>
              <br />
              <div className="review-data">
                <p>
                ARYO is the best thing that has happened to me. I had lost my job and ARYO platform came to my rescue during my difficult time. Best part is I receive all my payouts and commissions on time! What more do anyone want!
                </p>
              </div>
            </div>
            <div className="review-box">
              <div className="rating-wrapper">
                <div className="user-box">
                  <img src={anish} alt="" id="happy_user" />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  <p className="user_name">Anish Rachcha</p>
                </div>

                <div id="rating-box">
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                </div>
              </div>
              <br />
              <div className="review-data">
                <p>
                Best app to sell financial products & earn referral income. If someone is really looking forward to earn money, I would definitely recommend this app. With some efforts we can easily earn upto Rs 20000+ in a month with ARYO
                </p>
              </div>
            </div>
            <div className="review-box">
              <div className="rating-wrapper">
                <div className="user-box">
                  <img src={rajeev} alt="" id="happy_user" />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  <span className="user_name">Rajeev</span>
                </div>

                <div id="rating-box">
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                </div>
              </div>
              <br />
              <div className="review-data">
                <p>
                ARYO is a one of the best earning apps in the market. With ARYO anyone can start selling financial products and become financial independent. Best part is I have become my own boss!
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
