import React, { useEffect } from "react";
import "../css/MyModal.css";
import submitted from "../images/ok_logo_new.png";

function MyModal({ closeModal }) {
  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  return (
    <div className="modal-wrapper">
      <div className="modal-container">
          <img src={submitted} alt="" className="ok" />
        <p id="modal_success">Success</p>
        <p className="success_message">Thank you! for contacting us</p>
        <p className="success_message">We shall reach out you soon</p>

        <button className="okButton" onClick={closeModal}>
          Close
        </button>
      </div>
    </div>
  );
}

export default MyModal;
