import React, { useEffect, useState } from "react";
import "../css/bankdetails.css";
import fbutton from "../images/forward_arrow.png";
import { getBankName } from "../Connection";
import {
  validateAccountHolderName,
  validateAccountNumber,
  validateBankName,
  validateConfirmAccountNumber,
  validateIfscCode,
} from "./Validations";
import {
  auth,
  fetchBankDetails,
  updateBankDetails,
  updateBankDetailsInProfile,
} from "../Firebase";
import UpdatedMessageModal from "./UpdatedMessageModal";
import Error from "./Error";
import * as States from "./States";
import Spinner from "./Spinner";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

function BankDetails() {
  // const bank = useSelector((state) => state.bankreducer);
  // console.log("current bank ", bank)
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    ifsc: "",
    bankName: "",
    accHolderName: "",
    accNumber: "",
  });

  const [bankName, setBankName] = useState(formData.bankName);
  const [confirmAccNumber, setConfirmAccNumber] = useState(formData.accNumber);
  const [updateStatus, setUpdateStatus] = useState(null);

  const [showModal, setShowmodal] = useState(false);
  const [showError, setShowError] = useState(false);

  const closeModal = () => {
    setShowmodal(false);
    navigate('/details');
  };
  const closeError = () => {
    setShowError(false);
    navigate('/details');
  };

  const [formErrors, setFormErrors] = useState({
    ifsc: "",
    bankName: "",
    accHolderName: "",
    accNumber: "",
    confirmAccNumber: "",
  });

  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    user !== null &&
      fetchBankDetails((bank) => {
        if (bank !== null) {
          setFormData({
            ifsc: bank.ifsc,
            bankName: bank.bankName,
            accHolderName: bank.accHolderName,
            accNumber: bank.accNumber,
          });
          setConfirmAccNumber(bank.accNumber);
        }
      });
  }, [user]);

  useEffect(() => {
    if (!loading && user === null) {
      navigate("/login");
    }
  }, [loading, user]);

  useEffect(() => {
    if (bankName.length !== 0) {
      setFormData({ ...formData, ["bankName"]: bankName });
    }
  }, [bankName]);

  const onInputIfsc = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setFormData({ ...formData, [name]: value });
    if (value.length === 11) {
      getBankName(value, (result) => {
        if (result !== null) {
          setBankName(result.BANK);
        }
      });
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "confirmAccNumber") {
      setConfirmAccNumber(value);
    } else {
      setFormData({ ...formData, [name]: value.toUpperCase() });
    }
  };

  const onSubmit = () => {
    const ifscValidation = validateIfscCode(formData.ifsc);
    const bankNameValidation = validateBankName(formData.bankName);
    const accHolderNameValidation = validateAccountHolderName(
      formData.accHolderName
    );
    const accNumberValidation = validateAccountNumber(formData.accNumber);
    const confirmAccNumberValidation = validateConfirmAccountNumber(
      formData.accNumber !== confirmAccNumber
    );
    // console.log(formData.accNumber === confirmAccNumber)
    // console.log(confirmAccNumberValidation)

    setFormErrors({
      ifsc: ifscValidation,
      bankName: bankNameValidation,
      accHolderName: accHolderNameValidation,
      accNumber: accNumberValidation,
      confirmAccNumber: confirmAccNumberValidation,
    });

    // setFormErrors({
    //   name: fullNameValidation,
    //   mobile: mobileNumberValidation,
    //   email: emailValidation,
    //   pincode: pincodeValidation,
    // });

    // setFormErrors({
    //   ...formErrors,
    //   ["accHolderName"]: validateAccountHolderName(formData.accHolderName),
    // });
    // setFormErrors({
    //   ...formErrors,
    //   ["accNumber"]: validateAccountNumber(formData.accNumber),
    // });
    // setFormErrors({
    //   ...formErrors,
    //   ["bankName"]: validateBankName(formData.bankName),
    // });

    // console.log("any error ", formErrors.accHolderName.length === 0 &&
    // formErrors.bankName.length === 0 &&
    // formErrors.accNumber.length === 0 &&
    // formErrors.confirmAccNumber.length === 0 &&
    // formErrors.ifsc.length === 0)

    // console.log(formErrors);

    if (
      !ifscValidation &&
      !bankNameValidation &&
      !accHolderNameValidation &&
      !accHolderNameValidation &&
      !confirmAccNumberValidation
    ) {
      setUpdateStatus(States.INIT);
      document.body.style.overflowY = "hidden";
      updateBankDetails(formData, (result) => {
        if (result) {
          updateBankDetailsInProfile();
          setUpdateStatus(States.SUCCESS);
          document.body.style.overflowY = "scroll";
          setShowmodal(true);
        } else {
          setUpdateStatus(States.FAILURE);
          setShowError(true);
          document.body.style.overflowY = "scroll";
        }
      });
    }
  };

  // console.log(formErrors.confirmAccNumber);

  if (!loading && user !== null) {
    return (
      <>
        {showError && <Error closeError={closeError} />}
        {showModal && <UpdatedMessageModal closeModal={closeModal} />}
        {updateStatus === States.INIT && (
          <div className="center-screen">
            <Spinner />
          </div>
        )}

        <div className="bank-wrapper">
          {/* <CustomizedModal
            id={"kycId"}
            message={
              updateStatus === States.SUCCESS
                ? "Updated Successfully!"
                : "Something went wrong.\nPlease try again!"
            }
            open={[States.SUCCESS, States.FAILURE].includes(updateStatus)}
            close={() => {
              setUpdateStatus(null);
            }}
          /> */}

          <div className="bank_details-box">
            <div className="bank_details-screen">
              <div className="details_heading">
                <p>Please fill your account details</p>
              </div>
              <div className="detail_input_label">
                <p>IFSC CODE</p>
              </div>
              <div className="detail_input">
                <input
                  type="text"
                  name="ifsc"
                  maxLength={11}
                  value={formData.ifsc}
                  onChange={onInputIfsc}
                />
                {formErrors.ifsc && (
                  <div className="error-message" id="namingerror">
                    <span className="error-star"></span> {formErrors.ifsc}
                  </div>
                )}
              </div>

              <div className="detail_input_label">
                <p>BANK NAME</p>
              </div>
              <div className="detail_input">
                <input
                  type="text"
                  name="bankName"
                  onChange={onInputChange}
                  value={formData.bankName}
                />
                {formErrors.bankName && (
                  <div className="error-message" id="namingerror">
                    <span className="error-star"></span> {formErrors.bankName}
                  </div>
                )}
              </div>

              <div className="detail_input_label">
                <p>ACCOUNT HOLDER NAME</p>
              </div>
              <div className="detail_input">
                <input
                  type="text"
                  name="accHolderName"
                  maxLength={25}
                  value={formData.accHolderName}
                  onChange={onInputChange}
                />

                {formErrors.accHolderName && (
                  <div className="error-message" id="namingerror">
                    <span className="error-star"></span>{" "}
                    {formErrors.accHolderName}
                  </div>
                )}
                {formErrors.name_match && (
                  <div className="error-message" id="namingerror">
                    <span className="error-star"></span> {formErrors.name_match}
                  </div>
                )}
              </div>

              <div className="detail_input_label">
                <p>ACCOUNT NUMBER</p>
              </div>
              <div className="detail_input">
                <input
                  type="tel"
                  name="accNumber"
                  value={formData.accNumber}
                  onChange={onInputChange}
                />
                {formErrors.accNumber && (
                  <div className="error-message" id="namingerror">
                    <span className="error-star"></span> {formErrors.accNumber}
                  </div>
                )}
              </div>

              <div className="detail_input_label">
                <p>CONFIRM ACCOUNT NUMBER</p>
              </div>
              <div className="detail_input">
                <input
                  type="password"
                  name="confirmAccNumber"
                  value={confirmAccNumber}
                  onChange={onInputChange}
                />
                {formErrors.confirmAccNumber && (
                  <div className="error-message" id="namingerror">
                    <span className="error-star"></span>{" "}
                    {formErrors.confirmAccNumber}
                  </div>
                )}
              </div>
              <div className="bank_button" onClick={onSubmit}>
                <button>UPDATE</button>
                <img src={fbutton} alt="" id="fbutton" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <Spinner />;
  }
}
export default BankDetails;
