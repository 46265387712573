import React, { useEffect } from "react";
import "../css/MyModal.css";
import submitted from "../images/ok_logo_new.png";

function UpdatedMessageModal({ closeModal }) {
  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  return (
    <div className="modal-wrapper">
      <div className="modal-container">
          <img src={submitted} alt="" className="ok" />
        <p className="success_message">Updated Successfully</p>
      

        <button className="okButton" onClick={closeModal}>
          Close
        </button>
      </div>
    </div>
  );
}

export default UpdatedMessageModal;
