import React from "react";
import aryoicon from "../images/Aryo.png";
import supersalesman from "../images/supersalesman.png";
import "../css/benefits.css";

function Benefits() {
  return (
    <>
      <div className="benefits-wrapper">
        <div className="sales-logo">
          <img src={supersalesman} alt="" />
        </div>
        <div className="benefits-data">
          <p id="benefits-title">Why chose ARYO?</p>
          <p id="info-para">
            <img src={aryoicon} alt="" /> Multiple financial products on a
            single platform
          </p>
          <p id="info-para">
            <img src={aryoicon} alt="" /> Receive regular updates on all
            products
          </p>
          <p id="info-para">
            <img src={aryoicon} alt="" /> Guidance in getting suitable financial
            products
          </p>
          <p id="info-para">
            <img src={aryoicon} alt="" /> Proper Assistance
          </p>
        </div>
      </div>
    </>
  );
}

export default Benefits;
