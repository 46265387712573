
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithCustomToken, updateEmail } from "firebase/auth";
import {
  doc,
  getDoc,
  setDoc,
  getFirestore,
  Timestamp,
} from "firebase/firestore";

import { getStorage, getDownloadURL, ref, uploadBytes } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const FIRESTORE_DB = getFirestore(app);
const STORAGE = getStorage(app);

const updateFirebaseEmail = async (email, callBack) => {

  // console.log("updating email ", email)
  updateEmail(auth.currentUser, email)
    .then(()=>{
      // console.log("updated succesfully");
      callBack(true);
    })
    .catch((err) => {
      console.log("error updateEmail ", err);
      callBack(false);
    })
}

const updateProfile = async (data, callBack) => {

  // console.log(data);
  const DocRef = doc(FIRESTORE_DB, 'AryoDB', auth.currentUser.uid);
  setDoc(DocRef, data, { merge: true }).then(doc => {
    callBack(true);
  }).catch(err => {
    callBack(false);
  })
}

const updateBankDetailsInProfile = async () => {
  const docRef = doc(FIRESTORE_DB, `AryoDB/${auth.currentUser.uid}`)
  setDoc(docRef, { bankDetailSubmitted : true }, { merge: true });
}

const uploadImgBytes = async (img, name, callBack) => {
  // console.log(img, name)
  const imgRef = ref(STORAGE, `KYC/${auth.currentUser.uid}/${name}`)
  // const imgRef = STORAGE.ref().child(`KYC/${auth.currentUser.uid}/pan_${Timestamp.now().}`);
  uploadBytes(imgRef, img).then(value => {
    // console.log('uploadBytes : ', value)
    getDownloadURL(value.ref).then(url => {
      // console.log(url)
      callBack(url)
    })
  })
}

const updateBankDetails = async (data, callBack) => {
  data.lastUpdate = Timestamp.now();
  const DocRef = doc(FIRESTORE_DB, `AryoDB/${auth.currentUser.uid}/BankDetailsDB/BankDetails`);
  setDoc(DocRef, data, { merge: true }).then(doc => {
    callBack(true);
  }).catch(err => {
    console.log("error updating bank ", err);
    callBack(false);
  })
}

const fetchBankDetails = async (callback) => {
  const docRef = doc(FIRESTORE_DB, `AryoDB/${auth.currentUser.uid}/BankDetailsDB/BankDetails`);
  getDoc(docRef).then(docSnap => {
    if (docSnap.exists()) {
      callback(docSnap.data())
    }
    else {
      callback(null);
    }
  }).catch(err => {
    console.log("err reading profilr ", err)
  })
}

const updateKyc = async (data, callBack) => {
  // console.log(data)
  const kyc = {
    'agentName': data.name,
    'agentPan': data.pan,
    'agentDob' : data.dob,
    'agentAadhar': data.aadhar,
    'agentPanUrl': data.panUrl,
    'agentAadharUrl': data.aadharUrl,
    'agentAadharBackUrl': data.aadharBackUrl,
    'lastUpdate': Timestamp.now()
  }
  const DocRef = doc(FIRESTORE_DB, `AryoDB/${auth.currentUser.uid}/KycDB/Kyc`);
  setDoc(DocRef, kyc, { merge: true }).then(doc => {
    callBack(true);
  }).catch(err => {
    callBack(false);
  })
}

const fetchProfile = async (callback) => {

  const docRef = doc(FIRESTORE_DB, `AryoDB/${auth.currentUser.uid}`);
  getDoc(docRef).then(docSnap => {
    // console.log("docSnap", docSnap.data())
    if (docSnap.exists()) {
      callback(docSnap.data())
    }
    else {
      callback(null);
    }
  }).catch(err => {
    console.log("err reading profilr ", err)
  })
}

const getDKyc = async (callback) => {

  const docRef = doc(FIRESTORE_DB, `AryoDB/${auth.currentUser.uid}/KycDB/Kyc`);
  getDoc(docRef).then(docSnap => {
    // console.log("docSnap", docSnap.data())
    if (docSnap.exists()) {
      // console.log(docSnap.data().dkyc)
      callback(docSnap.data().dkyc)
    }
    else {
      callback(undefined);
    }
  }).catch(err => {
    console.log("err reading profilr ", err)
  })
}

const fetchKycDetails = async (callback) => {

  const docRef = doc(FIRESTORE_DB, `AryoDB/${auth.currentUser.uid}/KycDB/Kyc`);
  getDoc(docRef).then(docSnap => {
    // console.log("docSnap", docSnap.data())
    if (docSnap.exists()) {
      callback(docSnap.data())
    }
    else {
      callback(null);
    }
  }).catch(err => {
    console.log("err reading profilr ", err)
  })
}

export {
  app,
  auth,
  fetchProfile,
  updateProfile,
  fetchKycDetails,
  uploadImgBytes,
  updateKyc,
  getDKyc,
  updateFirebaseEmail,
  updateBankDetailsInProfile,
  updateBankDetails,
  fetchBankDetails,
  signInWithCustomToken,
};