import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import aryologo from "../images/aryologoblue.png";
import "../css/terms.css";

function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  });

  return (
    <>
      <div className="terms_container">
        <Link to="/" id="logo">
          <img alt="" className="img-fluid" src={aryologo} />{" "}
        </Link>

        <div>
          <h1 id="tnc">Terms and Conditions</h1>
          <h3>M/S GARNAR TECHNOLOGIES PRIVATE LIMITED (ARYO App)</h3>
          <h3>TERMS &amp; CONDITIONS cum USER AGREEMENT</h3>
          <h4>1. INTRODUCTION</h4>
          <p>
            Today, almost all organizations face challenges in handling Channel
            Partner Firms and Freelancers. Such challenges can include scaling
            issues or quality issues. Those organizations also face problems
            because they use many outdated methods. A one stop solution for the
            same is a mobile app- based platform where technology is used to
            help agents and organizations to reach their customers in an
            efficient and a scalable manner.Technology would help in handling
            channel partner firms and freelancers in a better manner because an
            app- based system has a pan India presence with a quick scaling
            process. The app- based system would involve a streamlined process
            which would thereby yield quality results. ARYO App (“ARYO,” “our,”
            “we,” or “us”) is one such one stop technological solution.
          </p>
          <p>
            Our Role: ARYO App is an android application which provides several
            services for handling channel partner firms and freelancers. Aryo
            would provide (i) Customer lead management; (ii) Follow up
            management; (iii) Agent offerings; (iv) Commission based work; (v)
            Agent earnings; and (vi) Dashboard and reports.
          </p>
          <p>
            Our expertise and services are useful for (i) B2C Projects; and (ii)
            B2B Projects. The former would include– (a) Customer Acquisition;
            (b) KYC; (c) Loans, Credit cards, Savings Account, Credit Line,
            Demat Accounts and insurance; (d) Lead Generation; (e) Verification;
            and (f) Collection. Similarly, the latter includes– (a) Merchant
            onboarding; (b) EDC Machine sales; (c) Current Account; (d) Business
            Loans; (e) Payment solution.
          </p>
          <p>
            By registering on our App and accessing the information, resources,
            services, products, and tools, you- User, understand and agree to
            accept and adhere to the following terms and conditions as stated in
            this policy along with the terms stated in our Privacy Policy
            (please refer to the Privacy Policy section below for more
            information). This agreement is in effect as of August 31, 2021 We
            reserve the right to change this Agreement from time to time without
            notice. You acknowledge and agree that it is your responsibility to
            review this User Agreement periodically to familiarize yourself with
            any modifications. Your continued use of our App after such
            modifications will constitute acknowledgement and agreement of the
            modified terms and conditions.
          </p>
          <h4>2. RESPONSIBLE USE AND CONDUCT</h4>
          <p>
            By using and registering our App and accessing the information,
            resources, services, products, and tools we provide for you, either
            directly or indirectly, you- the user agree to use our App only for
            the purposes intended as permitted by (a) the terms of this policy,
            and (b) applicable laws, regulations and generally accepted online
            practices or guidelines.
          </p>
          <ol type="a">
            <li>Wherein, you understand that:</li>
            <li>
              In order to access our App, you may be required to provide certain
              information about yourself (such as identification, contact
              details, etc.) as part of the registration process, or as part of
              your ability to use the tools on our App. You agree that any
              information you provide will always be accurate, correct, and up
              to date.
            </li>
            <li>
              You are responsible for maintaining the confidentiality of any
              login information associated with your account. Accordingly, only
              you are responsible for all activities that occur under your
              account/s.
            </li>
            <li>
              Accessing (or attempting to access) our App by any means other
              than through the means we provide, is strictly prohibited. You
              specifically agree not to access (or attempt to access) our App
              through any automated, unethical or unconventional means.
            </li>
            <li>
              Engaging in any activity that disrupts or interferes with the
              services provided by our App, including the servers and/or
              networks on which our App is located or connected, is strictly
              prohibited
            </li>
            <li>
              Attempting to copy, duplicate, reproduce, sell, trade, or resell
              the services provided on our App is strictly prohibited. You are
              solely responsible for any consequences, losses, or damages that
              we may directly or indirectly incur or suffer due to any
              unauthorized activities conducted by you, as explained above, and
              may incur criminal, civil, or intellectual property liability.
            </li>
            <li>
              Our App may display links to other websites including that of the
              third parties. We shall in no way be responsible in any way by
              your use of such websites. We shall not be responsible for any
              form of transmission, whatsoever, received by you from such
              websites. The information provided by You to such third- party
              websites shall be governed in accordance with the privacy policies
              of such websites, and it is recommended that you review such
              privacy policies. Accordingly, you agree and acknowledge that
              accessing such websites through links displayed on our App will
              solely be at your discretion.
            </li>
            <li>
              By posting information or otherwise using any open communication
              tools as mentioned, you agree that you will not upload, post,
              share or otherwise distribute any content that: (i) is illegal,
              threatening, defamatory, abusive, harassing, degrading, for our
              App;(ii) infringes on any trademark, patent, trade secret,
              copyright, etc of our App; (iii) contains any type of unauthorized
              or unsolicited advertising.
            </li>
            <li>
              We have the right at our sole discretion to remove the account of
              any user who has violated this policy or is otherwise responsible
              for posting offensive, harmful, objectionable, inaccurate
              information. We may also remove those accounts which violate 3rd
              party copyrights or trademarks. We are not responsible for any
              delay or failure in removing such content. If you post content
              that we choose to remove, you hereby consent to such removal, and
              consent to waive any claim against us.
            </li>
          </ol>
          <ol type="i">
            <li>
              We do not assume any liability for any content posted by you or
              any other third- party on our website.
            </li>
            <li>
              All information provided as part of our registration process is
              covered by our privacy policy.
            </li>
            <li>
              You agree to indemnify and hold ARYO App, M/s GARNAR TECHNOLOGIES
              PRIVATE LIMITED and its affiliates, their directors, officers,
              managers, employees, donors, agents, and licensors, from and
              against all losses, expenses, damages and costs, including
              reasonable attorneys' fees, resulting from any violation of this
              policy or the failure to fulfill any obligations relating to your
              account incurred by you or any other person using your account. We
              reserve the right to take over the exclusive defense of any claim
              for which we are entitled to indemnification under this policy. In
              such event, you shall provide us with such cooperation as is
              reasonably requested by us.
            </li>
            <li>
              Any disputes arising out of the above will only be tried in the
              appropriate courts of Delhi.
            </li>
          </ol>
          <h4>3. PRIVACY</h4>
          <p>
            Your privacy is very important to us, which is why we’ve created a
            separate Privacy Policy in order to explain in detail how we
            collect, manage, process, secure, and store your private
            information. To read our privacy policy in its entirety
          </p>
          <h4>4. LIMITATION OF WARRANTIES</h4>
          <ol type="a">
            <li>
              We do not guarantee that the use of our App will meet your needs
              or requirements.
            </li>
            <li>
              The services of our App will always be uninterrupted, timely,
              secure and free from errors.
            </li>
            <li>
              Any defects in the operation or functionality of our App will be
              repaired or corrected. Furthermore, you understand and agree that:
              Any link opened or third party website accessed through the use of
              our App is done at your own discretion and risk, and that you are
              solely responsible for any damage to your computer or other
              devices for any loss of data that may result from the download of
              such content. No information or advice, whether expressed,
              implied, oral or written, obtained by you from ARYO App or M/s
              GARNAR TECHNOLOGIES PRIVATE LIMITED shall create any warranty,
              guarantee, or conditions of any kind, except for those expressly
              outlined in this policy.
            </li>
          </ol>
          <h4>5. LIMITATION OF LIABILITY</h4>
          <p>
            In conjunction with the Limitation of Warranties as explained above,
            you the user expressly understand and agree that ARYO App is merely
            a facilitator and we shall not be liable for any direct, indirect,
            incidental, consequential or exemplary loss or damages which may be
            incurred by you as a result of using our App, or as a result of any
            changes, data loss or corruption, cancellation, loss of access, or
            downtime to the full the extent that applicable limitation of
            liability laws apply.
          </p>
          <h4>6. COPYRIGHTS/TRADEMARKS</h4>
          <p>
            All content and materials available on our APP, including but not
            limited to text, graphics, App name, code, images and logos are the
            intellectual property of M/s GARNAR TECHNOLOGIES PRIVATE LIMITED,
            and are protected by applicable copyright and trademark law. Any
            inappropriate use, including but not limited to the reproduction,
            distribution, display or transmission of any content on this site is
            strictly prohibited, unless specifically authorized by M/s GARNAR
            TECHNOLOGIES PRIVATE LIMITED.
          </p>
          <h4>7. TERMINATION OF USE</h4>
          <p>
            You agree that we may, at our sole discretion, suspend or terminate
            your access to all or part of our App with or without notice and for
            any reason, including, for breach of this policy. Any suspected
            illegal, fraudulent or abusive activity may be grounds for
            terminating your relationship and may be referred to appropriate law
            enforcement authorities. Upon suspension or termination, your right
            to use our App will immediately cease, and we reserve the right to
            remove or delete any information that you may have recorded on file
            with us, including any account or login information.
          </p>
          <h4>8. GOVERNING LAW</h4>
          <p>
            Our App is controlled by M/s GARNAR TECHNOLOGIES PRIVATE LIMITED
            from our offices located in __________, India. It can be accessed on
            any Android software device throughout India. By accessing our App,
            you agree that the statutes and laws of Delhi, India will apply to
            all matters relating to the use of our App. Furthermore, any action
            to enforce this policy shall be brought in the courts located in New
            Delhi. You hereby agree to personal jurisdiction by such courts and
            waive any jurisdictional, venue, or inconvenient forum objections to
            such courts.
          </p>
          <h4>9. GUARANTEE</h4>
          <p>
            Unless otherwise expressed, M/s GARNAR TECHNOLOGIES PRIVATE LIMITED.
            expressly disclaims all warranties and conditions of any kind,
            whether express or implied, including, but not limited to the
            implied warranties and conditions of merchantability, fitness for a
            particular purpose and noninfringement
          </p>
          <h4>10. Cancellation And Refund</h4>
          <p>
            <br />
            All sales of Recharge/bill payments are final and there will be no
            refund or exchange permitted. Please be advised that you are
            responsible for the account number / Customer ID (eg. Mobile number
            for recharges, DTH Account number in case of DTH recharge
            etc.)&nbsp;you enter&nbsp;and all charges that result from those
            purchases.&nbsp; <b>ARYO (GARNAR TECHNOLOGIES PRIVATE LIMITED)</b>is
            not responsible for any purchase of Recharge or Bill Payment
            service&nbsp;for an incorrect account number / Customer ID.
            <br />
            <br />
            However, in a case where a transaction has been completed by you on
            the Site, and money has been charged to your card or bank account
            but a Recharge has not delivered within 24 hours of your completion
            of the transaction then you may inform us by sending us an email
            on&nbsp;
            <strong>contactus@aryo.club&nbsp;</strong>or posting us a message on
            the Contact Us section of our app. In such a scenario you will be
            entitled to a full refund. We request you to include in the email
            the following details - Account Number / Customer ID, operator name,
            Recharge value/ Bill Value, Transaction date and Order Number.&nbsp;
            <strong>GARNAR TECHNOLOGIES PRIVATE LIMITED&nbsp;</strong>shall
            investigate the incident and if it is found that money was indeed
            charged to your card or bank account without delivery of the
            Recharge then you will be refunded the money within 7 to 10 working
            days from the date of the receipt of your email.
            <br />
            <br />
            Kindly note, in all cases our liability is only restricted to
            providing you a valid recharge or refund to the extent of payment
            received by us. We shall not be responsible for any other claim or
            consequential liability arising out of a failed recharge on our
            system.
          </p>
          <h4>11.CONTACT INFORMATION</h4>
          <p>
            If you have any questions or comments about these our Terms of
            Service as outlined above, you can contact us at:
          </p>
          <p>
            M/s GARNAR TECHNOLOGIES PRIVATE LIMITED
            <br />
            Website: www.aryo.club
            <br />
            Email Id: contactus@aryo.club
          </p>
          <p>
            UNIT NO. 129-130, WESTEND MALL, Janakpuri, Janakpuri
            <br />
            District Centre, New Delhi, West Delhi, Delhi, 110058
          </p>
          <p />
        </div>
      </div>
    </>
  );
}

export default TermsAndConditions;
