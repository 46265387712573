import React, { useEffect } from "react";
import Banner from "./Banner";
import Products from "./Products";
import Offers from "./Offers";
import AppInfo from "./AppInfo";
import Steps from "./Steps";
import AppAchievement from "./AppAchievement";
import Header from "./Header";
import Reviews from "./Reviews";
import Aos from "aos";
import "aos/dist/aos.css";
import "../css/home.css";

function Home() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  });

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <div className="banner-screen">
        <div className="header-container">
          <Header />
        </div>
        <Banner />
      </div>
      <Offers />
      <Products />
      <Steps />
      <AppAchievement />
      <AppInfo />
      <Reviews />
    </>
  );
}

export default Home;
