import React, { useEffect, useState } from "react";
import Navbar2 from "./Navbar2";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import { auth } from "../Firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function DetailsView() {
  const navigate = useNavigate();

  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (!loading && user === null) {
      navigate("/login");
    }
  }, [loading, user]);

  if (!loading && user !== null) {
    return (
      <div>
        <Navbar2 />
      </div>
    );
  } else {
    return (
      <>
        <Spinner />
      </>
    );
  }
}

export default DetailsView;
