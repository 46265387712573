export const fullNameRegex = /^[0-9A-Za-z\s]+$/;

export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export function validateFullName(inputValue) {
  
  if (inputValue.trim() === "") {
    return "Name cannot be empty";
  } else if (/^\d/.test(inputValue)) {
    return "Name cannot start with a number";
  } else if (!fullNameRegex.test(inputValue)) {
    return "Name should contain only letters, spaces, and numbers";
  }
  return "";
}

export function validateDobDetails(dateOfBirth) {
  if (dateOfBirth === "") {
    return "Please enter you DOB";
  } else {
    return "";
  }
}

export function validateIfscCode (ifscCode){

    if(ifscCode.length !== 11 || ifscCode === ""){
      return "Please enter valid ifsc code";
    }
    else{
      return "";
    }

}

export function validateAccountHolderName (accholderName) {
  // console.log(accholderName)
  if (accholderName.trim() === "") {
    return "Name cannot be empty";
  } else if (/^\d/.test(accholderName)) {
    return "Name cannot start with a number";
  } else if (!fullNameRegex.test(accholderName)) {
    return "Name should contain only letters, spaces, and numbers";
  }
  return "";
}

export function validateBankName (bankName)  {
  if(bankName === ""){
    return "Please enter valid bank name "
  }
  else{
    return "";
  }
}

export function validateConfirmAccountNumber (isMatching) {
  // console.log(status);
  if(isMatching){
    return 'Account number mismatch';
  }
  else{
    return '';
  }
}

export function validateAccountNumber(accountNumber) {
  if(accountNumber.length < 11 || accountNumber === ""){
    return "Plese enter valid account number";
  }

  else{
    return "";
  }
}

export function validateEmail(inputValue) {
  if (!emailRegex.test(inputValue)) {
    return "Please enter a valid email";
  }
  return "";
}

export function validateMobileNumber(mobileNumber) {
  const mobile = mobileNumber.replace(/[^0-9]/g, "");
  if (mobile.trim().length !== 10) {
    return "Mobile number must have 10 digits";
  }
  return "";
}

export function validatePanDetails(panNumber) {
  if (panNumber.length !== 10 || panNumber === "") {
    return "Please enter 10 digit pan number";
  } else {
    return "";
  }
}

export function validateAadharDetails(aadharNumber) {
  if (aadharNumber.length !== 12 || aadharNumber === "") {
    return "Please enter your valid aadhar number";
  } else {
    return "";
  }
}

export function validateGSTIN(gstInNumber) {
  if (gstInNumber.length !== 14 || gstInNumber === "") {
    return "Please enter valid GSTIN number";
  }
}

export function validatePincode(inputValue, projectName, listPincodes) {
  const pc = inputValue.replace(/\D/g, "");

  if (inputValue === "") {
    return "Pincode cannot be empty";
  }
  if (inputValue.length !== 6) {
    return "Pincode must be 6 digits";
  }

  if (
    projectName === "India Infoline" &&
    !listPincodes.includes(parseInt(pc))
  ) {
    return "Pincode not found";
  }
  return "";
}

export function validateCategory(inputValue) {
  if (inputValue === "") {
    return "Please select a category";
  } else {
    return "";
  }
}

export const validateFullNameInput = (name) => {
  if (/^\d/.test(name)) {
    return "Name cannot start with a number";
  } else {
    return "";
  }
};

export const validateEmailInput = (email) => {
  return email.trim() === "" ? "" : "";
};

export const validateMobileNumberInput = (mobile) => {
  return mobile.replace(/[^0-9]/g, "");
};

export const validatePincodeInput = (pc, projectName, listPincodes) => {
  pc = pc.replace(/\D/g, "");

  if (projectName === "India Infoline") {
    if (pc.length === 6 && !listPincodes.includes(parseInt(pc))) {
      return "Pincode not found";
    }
  }
  return "";
};
