import { ImageSlider } from "./ImageSlider";
import img1 from "../images/slideImages/ss1.png";
import img2 from "../images/slideImages/ss2.png";
import img3 from "../images/slideImages/ss3.png";
import img4 from "../images/slideImages/ss4.png";

const IMAGES = [img1, img2, img3, img4];

export default function Slider() {
  return (
    <div >
      <ImageSlider images={IMAGES} />
    </div>
  );
}
