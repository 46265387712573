import React from "react";
import "../css/partner.css";
// import Slider from "react-slick";
// import "../css/slick.css";
// import "../css/slick-theme.css";
import au from "../images/au_small_finance.png";
import indusInd from "../images/indusInd.png";
import lic from "../images/lic.png";
import idfc from "../images/idfc_first.png";
import bajaj from "../images/bajaj.png";
import axis from "../images/axis.png";
import bob from "../images/bob.png";
import hdfc from "../images/hdfc_logo.png";

function Partners() {
  return (
    <>
      <div className="partner-wrapper">
        <p id="partner-heading">
          Our <span id="p1"> Banking Partners </span>
        </p>
        <div className="partner-logos-container">
          <div className="partner-logos">
            <img src={au} alt="" id="au" />
          </div>
          <div className="partner-logos">
            <img src={indusInd} alt="" />
          </div>
          <div className="partner-logos">
            <img src={lic} alt="" />
          </div>
          <div className="partner-logos">
            <img src={idfc} alt="" id="idfc" />
          </div>
          <div className="partner-logos">
            <img src={bajaj} alt="" />
          </div>
          <div className="partner-logos">
            <img src={axis} alt="" />
          </div>

          <div className="partner-logos">
            <img src={bob} alt="" />
          </div>
          <div className="partner-logos">
            <img src={hdfc} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Partners;
