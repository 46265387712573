import React, { useState } from "react";
import login_banner from "../images/otp_logo.png";
import fbutton from "../images/forward_arrow.png";
import "../css/login.css";

export default function EnterMobile({ setMobile }) {
  const [enteredMobile, setEnteredMobile] = useState("");
  const [mobileError, setMobileError] = useState("");

  const onInputChange = (e) => {
    const { value } = e.target;
    setEnteredMobile(value);
  };

  return (
    <div className="login-wrapper">
      <div className="login-banner">
        <div className="banner-text">
          <p>Become a Aryo Partner DSA</p>
          <p>and Earn upto ₹50,000/Month*</p>
          <p>Zero Investment Required</p>
        </div>
        <img src={login_banner} alt="" />
      </div>
      <div className="login-box">
        <div className="otp_screen">
          <div className="login-heading">
            <p className="login_page_heading">Verify your mobile number</p>
          </div>
          <div className="mobile_input_label">
            <p>Enter your mobile number</p>
          </div>
          <div className="mobile_input">
            <input
              type="tel"
              name="mobile"
              id="mobile_number"
              onChange={onInputChange}
              maxLength={10}
              value={enteredMobile}
            />
          </div>
          <div className="mobile-error"> {mobileError && <p>{mobileError}</p>}</div>

          <div
            className="otp_button"
            onClick={() => {
              // console.log("enteredMobile ", enteredMobile);
              if (/^\d{10}$/.test(enteredMobile)) {
                setMobile(enteredMobile);
              } else {
                setMobileError("Please enter valid mobile number");
              }
            }}
          >
            <button>GET OTP</button>
            <img src={fbutton} alt="" id="fbutton" />
          </div>

          <div className="termsandconditions">
            <p>
              By continuing, I agree to your{" "}
              <span className="terms-policy">Terms & Conditions</span> and the
              <span className="terms-policy"> Privacy Policy </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
