import React, { useEffect, useState, useRef } from "react";
import { reGenerateOtp, generateOtp, verifyOtp } from "../Connection";
import Spinner from "./Spinner";
import { OTP_VERIFIED } from "./States";
import otp_banner from "../images/verify_otp_banner.png";
import "../css/otp.css";

const Timer = React.memo(function Timer({
  setShowTimer,
  seconds = 60,
}) {
  const [showSec, setShowSec] = useState(seconds);

  useEffect(() => {
    const timer =
      showSec > 0 &&
      setTimeout(() => {
        setShowSec((showSec) => showSec - 1)
      }, 1000);

    if (showSec === 0) {
      setShowTimer(false);
    }
    return () => clearInterval(timer);
  }, [setShowTimer, showSec]);

  return <span>Resend OTP in {showSec}</span>;
});

function VerifyOtp({ mobile, setUserState }) {
  const otpEntered = { otp: "" };
  const otpError = "Please enter valid OTP";
  const [formOtp, setFormOtp] = useState(otpEntered);
  const [orderId, setOrderId] = useState(null);
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showTimer, setShowTimer] = useState(true);
  const isOtpSentRef = useRef(false);
  const [isVerifyOtpDisabled, setIsVerifyOtpDisabled] = useState(true);


  useEffect(() => {

    if (!isOtpSentRef.current && orderId === null) {
        isOtpSentRef.current = true;
        generateOtp('91' + mobile, (result) => {
          if (result.status === 'success') {
            setOrderId(result.orderId);
          }
        });
      
    }
  }, []);

  const verifyEnteredOtp = () => {
    setShowSpinner(true);
    verifyOtp('91' + mobile, orderId, formOtp.otp, (result) => {
      if (result.status === 'success' && result.isOTPVerified === true) {
        setUserState(OTP_VERIFIED);
        setShowSpinner(false);
      } else {
        setIsOtpValid(false);
        setShowSpinner(false);
      }
    });
  };

  const handleOtpChange = (e) => {
    setIsOtpValid(true);
    const { name, value } = e.target;
    setFormOtp({ ...formOtp, [name]: value });
    if(value.length === 6){
      setIsVerifyOtpDisabled(false);
    }
    else{
      setIsVerifyOtpDisabled(true);
    }
  
  };

  const resendOtp = (e) => {
    setIsOtpValid(true)
    e.preventDefault();
    reGenerateOtp(orderId, (result) => {});
  };

  return (
    <>
      {showSpinner && <Spinner />}
      <div className="otp-wrapper">
        <div className="otp-banner">
          <img src={otp_banner} alt="" />
        </div>
        <div className="otp-box">
          <div className="otp_screen">
            <div className="otp_heading">
              <p className="otp_page_heading">Verify your mobile number</p>
            </div>
            <div className="otp_message">
              <p>We have sent to otp to your phone number</p>
              <h4 className="mt-2">{mobile}</h4>
            </div>
            <div className="otp_input_label">
              <p>Enter OTP</p>
            </div>
            <div className="otp_input">
              <input
                type="tel"
                name="otp"
                id="otp"
                defaultValue={""}
                maxLength={6}
                onChange={handleOtpChange}
              />
                {(!isOtpValid && <span> {otpError} </span>)}
            </div>
          
            <div className={`verify_otp_button ${isVerifyOtpDisabled ? "disabled" : ""}`} 
            onClick={() =>{if(!isVerifyOtpDisabled) {
              verifyEnteredOtp()
            }}}>
              <button id="getOtp" disabled={isVerifyOtpDisabled}>
                VERIFY OTP
              </button>
            </div>
            <div className="resend-box">
              <div className="content ">
                {showTimer ? (
                  <Timer setShowTimer={setShowTimer} />
                ) : (
                  <p className="resend" id="resendotpId" onClick={resendOtp}>
                    Resend
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyOtp;
