import React, { useState, useEffect } from "react";
import StepsMobile from "./StepsMobile";
import done from "../images/done.png";
import Slider from "./Slider";
import "../css/progress.css";

function Steps() {
  const [currentStep, setCurrentStep] = useState(1);
  const [circleActive1, setCircleActive1] = useState(true);
  const [circleActive2, setCircleActive2] = useState(false);
  const [circleActive3, setCircleActive3] = useState(false);
  const [circleActive4, setCircleActive4] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  const totalSteps = 4;

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentStep <= totalSteps) {
        setCurrentStep((prevStep) => prevStep + 1);
      } else {
        setCurrentStep(1);
      }

      if (currentStep === 1) {
        setCircleActive2(true);
        const infoElement = document.querySelector(".indicator");

        if (infoElement) {
          infoElement.style.transitionDuration = "500ms";
        }
      } else if (currentStep === 2) {
        setCircleActive3(true);
        const infoElement = document.querySelector(".indicator");

        if (infoElement) {
          infoElement.style.transitionDuration = "500ms";
        }
      } else if (currentStep === 3) {
        setCircleActive4(true);
        const infoElement = document.querySelector(".indicator");

        if (infoElement) {
          infoElement.style.transitionDuration = "500ms";
        }
      } else if (currentStep === 4) {
        setCircleActive2(false);
        setCircleActive3(false);
        setCircleActive4(false);
        setCurrentStep(1);
        const infoElement = document.querySelector(".indicator");

        if (infoElement) {
          infoElement.style.transitionDuration = "0s";
        }
      } else {
      }
    }, 3500);
    if (initialRender) {
      setCircleActive1(true);
      setCircleActive2(false);
      setCircleActive3(false);
      setCircleActive4(false);
      setCurrentStep(1);
      setInitialRender(false);
    }

    return () => clearInterval(interval);
  }, [currentStep, totalSteps, initialRender]);

  return (
    <>
      <div className="progress-wrapper">
        <p id="offers-heading">
          {" "}
          <span className="blue">Simple</span> Application Process
        </p>
        <p id="subheading">(Follow 4 simple steps)</p>
        <div className="container">
          <div className="step-container">
            <div className="steps">
              <span className={`circle ${circleActive1 ? "active" : ""}`}>
                {circleActive1 ? (
                  <img src={done} alt="" className="step_img" />
                ) : (
                  1
                )}
                <span></span>
              </span>

              <div className="progress-bar">
                <span
                  className="indicator"
                  style={{
                    height: `${((currentStep - 1) / (totalSteps - 1)) * 100}%`,
                  }}
                />
              </div>

              <span className={`circle ${circleActive2 ? "active" : ""}`}>
                {circleActive2 ? (
                  <img src={done} alt="" className="step_img share" />
                ) : (
                  2
                )}
              </span>

              <div className="progress-bar">
                <span
                  className="indicator"
                  style={{
                    height: `${((currentStep - 1) / (totalSteps - 1)) * 100}%`,
                  }}
                />
              </div>

              <span className={`circle ${circleActive3 ? "active" : ""}`}>
                {circleActive3 ? (
                  <img src={done} alt="" className="step_img process" />
                ) : (
                  3
                )}
              </span>

              <div className="progress-bar">
                <span
                  className="indicator"
                  style={{
                    height: `${((currentStep - 1) / (totalSteps - 1)) * 100}%`,
                  }}
                />
              </div>

              <div className="progress-bar">
                <span
                  className="indicator fifth"
                  style={{
                    height: `${((currentStep - 1) / (totalSteps - 1)) * 100}%`,
                  }}
                />

                <div className="progress-bar">
                  <span
                    className="indicator fifth"
                    style={{
                      height: `${
                        ((currentStep - 1) / (totalSteps - 1)) * 100
                      }%`,
                    }}
                  />
                </div>
              </div>

              <span className={`circle ${circleActive4 ? "active" : ""}`}>
                {circleActive4 ? (
                  <img src={done} alt="" className="step_img" />
                ) : (
                  4
                )}
              </span>
            </div>

            <div className="steps steps_details">
              <span className={`info  ${circleActive1 ? "active" : ""}`}>
                Choose a financial product to sell
              </span>

              <span
                className={`info secondstep  ${circleActive2 ? "active" : ""}`}
              >
                Share links with your customers
              </span>

              <span
                className={`info thirdstep ${circleActive3 ? "active" : ""}`}
              >
                Help customers in completing the application process
              </span>

              <span
                className={`info fourthstep ${circleActive4 ? "active" : ""}`}
              >
                Get your payouts directly in your account
              </span>
            </div>
          </div>
          <div className="ss-slider">
            <Slider />
          </div>
        </div>
      </div>
      <div className="steps-wrapper">
        <StepsMobile />
      </div>
    </>
  );
}

export default Steps;
