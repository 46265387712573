import React, { useState } from "react";
import * as Validation from "./Validations";
import { submitEnquiry, checkPincode } from "../Connection";
import Spinner from "./Spinner";
import Error from "./Error";
import Loader from "./Loader";
import MyModal from "./MyModal";
import * as States from "./States";
import dsa from "../images/company_banner.png";
import "../css/contactpageStyles.css";

function ContactUs() {
  const formElements = {
    name: "",
    email: "",
    mobile: "",
    pincode: "",
    message: "",
  };
  const [formData, setFormData] = useState(formElements);
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    mobile: "",
    pincode: "",
  });

  const [isLoadingPincode, setIsLoadingPincode] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showModal, setShowmodal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPincodeVerified, setIsPincodeVerified] = useState(false);
  const closeModal = () => {
    setShowmodal(false);
  };
  const closeError = () => {
    setShowError(false);
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
    setFormData({ ...formData, [name]: value });
  };

  const fetchPincodeData = (pincode) => {
    setIsLoadingPincode(true);
    const name = "pincode";
    checkPincode(pincode, (response) => {
      if (response.valid) {
        setIsLoadingPincode(false);
        setFormErrors({ ...formErrors, [name]: "" });
        setIsPincodeVerified(true);
      } else {
        setIsLoadingPincode(false);
        setFormErrors({ ...formErrors, [name]: States.INVALID_PINCODE });
        setIsPincodeVerified(false);
      }
    });
  };

  const handlePincodeChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (/^\d{6}$/.test(value)) {
      fetchPincodeData(value);
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
      setIsPincodeVerified(false);
    }
  };

  let submitDetails = () => {
    setIsFormSubmitted(true);

    const fullNameValidation = Validation.validateFullName(formData.name);
    const emailValidation = Validation.validateEmail(formData.email);
    const mobileNumberValidation = Validation.validateMobileNumber(
      formData.mobile
    );
    const pincodeValidation = Validation.validatePincode(formData.pincode);

    setFormErrors({
      name: fullNameValidation,
      email: emailValidation,
      mobile: mobileNumberValidation,
      pincode: pincodeValidation,
    });

    if (
      !fullNameValidation &&
      !emailValidation &&
      !mobileNumberValidation &&
      !pincodeValidation
    ) {
      setLoading(true);
      submitEnquiry(formData, (res) => {
        if (res.status === States.SUCCESS) {
          setFormData({
            ...formData,
            name: "",
            mobile: "",
            email: "",
            pincode: "",
            message: "",
          });
          setLoading(false);
          setShowmodal(true);
        } else {
          setLoading(false);
          setFormData({
            ...formData,
            name: "",
            mobile: "",
            email: "",
            pincode: "",
            message: "",
          });
          setShowError(true);
        }
      });
    }
  };

  return (
    <>
      {loading && <Spinner />}
      {showError && <Error closeError={closeError} />}
      {showModal && <MyModal closeModal={closeModal} />}
      <div className="contact-wrapper">
        <div>
          <div className="contact-container">
            <div className="contact-box">
              <div className="dsa-info">
                <p className="highlighted-heading">India's leading online</p>
                <p className="normal-heading"> Financial Products</p>
                <p className="highlighted-heading">distribution Company</p>

                <img src={dsa} alt="" id="aryopartner" />
              </div>
              <div className="contact-form">
                <p id="our-message">
                  Join as ARYO's DSA and explore better earning opportunities
                </p>
                <div className="input-Box">
                  <label htmlFor="fname">Name</label>
                  <input
                    type="text"
                    name="name"
                    id="fname"
                    maxLength={25}
                    onChange={onInputChange}
                    value={formData.name}
                  />
                  {isFormSubmitted && formErrors.name && (
                    <div className="error-message" id="namingerror">
                      {formErrors.name}
                    </div>
                  )}
                </div>
                <div className="input-Box">
                  <label htmlFor="email">Email address</label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    onChange={onInputChange}
                    value={formData.email}
                  />
                  {isFormSubmitted && formErrors.email && (
                    <div className="error-message" id="emailerror">
                      {formErrors.email}
                    </div>
                  )}
                </div>
                <div className="input-Box">
                  <label htmlFor="phone">Phone number</label>
                  <input
                    type="tel"
                    name="mobile"
                    id="phone"
                    maxLength={10}
                    onChange={onInputChange}
                    value={formData.mobile}
                  />

                  {isFormSubmitted && formErrors.mobile && (
                    <div className="error-message" id="mobileerror">
                      {formErrors.mobile}
                    </div>
                  )}

                  <div className="error-message" id="mobileerror">
                    <span className="error-star" />
                  </div>
                </div>

                <div className="input-Box">
                  <label htmlFor="phone">Pincode</label>
                  <input
                    type="tel"
                    name="pincode"
                    id="pincode"
                    maxLength={6}
                    onChange={handlePincodeChange}
                    value={formData.pincode}
                  />

                  <i></i>

                  {isLoadingPincode && (
                    <span className="pincode-spinner">
                      <Loader />
                    </span>
                  )}

                  {isFormSubmitted && formErrors.pincode && (
                    <div className="error-message" id="mobileerror">
                      {formErrors.pincode}
                    </div>
                  )}
                  {!isFormSubmitted && formErrors.pincode && (
                    <div className="error-message">
                      <span className="error-star"></span>
                      {formErrors.pincode}
                    </div>
                  )}

                  <div className="error-message" id="mobileerror">
                    <span className="error-star" />
                  </div>
                </div>

                <div className="input-Box-textArea">
                  <label htmlFor="phone">Message</label>
                  <textarea
                    name="message"
                    id="message"
                    cols={45}
                    rows={1}
                    onChange={onInputChange}
                    placeholder="Type your message here(Optional)"
                    value={formData.message}
                    style={{ resize: "none" }}
                  />
                </div>
                <div>
                  <button
                    id="submit-button"
                    onClick={submitDetails}
                    disabled={!isPincodeVerified}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
