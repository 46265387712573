import React from "react";
import payout from "../images/payouts.png";
import reports from "../images/reports.png";
import services from "../images/services.png";
import products from "../images/multiple_new.png";
import anywhere from "../images/anywhere.png";
import regfees from "../images/zero_investment.png";

import "../css/offers.css";

function Offers() {
  return (
    <div className="offers-wrapper">
      <p id="offers-heading">
        Why Choose <span className="blue"> ARYO</span>?
      </p>
      <div className="offers-container">
        <div className="offers-logo" data-aos="fade-up">
          <img src={payout} alt="" />
          <p className="offers-name">Best Payouts</p>
        </div>
        <div className="offers-logo" data-aos="fade-up">
          <img src={reports} alt="" />
          <p className="offers-name">Lead tracking & Payout reports</p>
        </div>
        <div className="offers-logo" data-aos="fade-up">
          <img src={services} alt="" />
          <p className="offers-name">Regular Product Trainings</p>
        </div>
        <div className="offers-logo" data-aos="fade-up">
          <img src={regfees} alt="" />
          <p className="offers-name">Zero Investment</p>
        </div>
        <div className="offers-logo" data-aos="fade-up">
          <img src={anywhere} alt="" />
          <p className="offers-name">Work Anywhere, Anytime</p>
        </div>
        <div className="offers-logo" data-aos="fade-up">
          <img src={products} alt="" />
          <p className="offers-name">Multiple Brands</p>
        </div>
      </div>
    </div>
  );
}

export default Offers;
