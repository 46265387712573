import React, { useEffect, useState } from "react";
import { verifyPan } from "../Connection";
import "../css/kyc.css";
import "../css/style.css";
import fbutton from "../images/forward_arrow.png";
import * as States from "./States";
import {
  auth,
  fetchKycDetails,
  getDKyc,
  updateKyc,
  uploadImgBytes,
} from "../Firebase";
import Spinner from "./Spinner";
import UpdatedMessageModal from "./UpdatedMessageModal";
import Error from "./Error";
import "../css/style.css";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

function KycDetails() {
  const [dKyc, setDkyc] = useState(false);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    pan: "",
    aadhar: "",
    dob: "",
    panUrl: null,
    aadharUrl: null,
    aadharBackUrl: null,
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    pan: "",
    aadhar: "",
    dob: "",
    panUrl: "",
    aadharUrl: "",
    aadharBackUrl: "",
  });

  const [imageFile, setImageFile] = useState({
    pan: "",
    aadhar: "",
    aadharBack: "",
  });

  const [uploadingState, setUploadingState] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(null);

  const [user, loading] = useAuthState(auth);

  const [showModal, setShowmodal] = useState(false);
  const [showError, setShowError] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    
    const localKyc = localStorage.getItem("dkyc");
    // console.log(localKyc)
    if (localKyc === undefined) {
      //try to fetch
      getDKyc((kyc) => {
        setDkyc(kyc === "true" ? true : false);
      });
    } else {
      setDkyc(localKyc === "true" ? true : false);
    }
  }, [dKyc]);

  useEffect(() => {
    if (user !== null) {
      fetchKycDetails((kycDetails) => {
        // console.log(kycDetails);
        if (kycDetails !== null) {
          setFormData({
            name: kycDetails.agentName,
            dob: kycDetails.agentDob,
            pan: kycDetails.agentPan,
            aadhar: kycDetails.agentAadhar,
            panUrl: kycDetails.agentPanUrl,
            aadharUrl: kycDetails.agentAadharUrl,
            aadharBackUrl: kycDetails.agentAadharBackUrl,
          });

          if (dKyc) {
            setIsDisabled(true);
          } else {
            setIsDisabled(false);
          }
        }
      });
    }
  }, [user, dKyc]);

  useEffect(() => {
    if (!loading && user === null) {
      navigate("/login");
    }
  }, [loading, user]);

  const verifyPanChange = (e) => {
    const { name, value } = e.target;

    setFormErrors({ ...formErrors, [name]: "" });
    setFormData({ ...formData, [name]: value.toUpperCase() });

    if (value.length === 10) {
      verifyPan(value.toUpperCase(), (result) => {
        // console.log(formData.name, " verify pan ", result.name)
        if (
          result.status === States.SUCCESS.toUpperCase() &&
          formData.name === result.name
        ) {
          setFormErrors({ ...formErrors, [name]: "" });
        } else {
          setFormErrors({ ...formErrors, [name]: "PAN name mismatch error" });
        }
      });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const onInputAadhar = (e) => {
    const { name, value } = e.target;
    setFormErrors({ ...formErrors, [name]: "" });
    const regExp = /^[2-9]{1}[0-9]{11}$/;
    // console.log(value, ' isAadhar valid ', regExp.test(value))
    setFormData({ ...formData, [name]: value.toUpperCase() });
    if (regExp.test(value)) {
      setFormData({ ...formData, [name]: value.toUpperCase() });
      setFormErrors({ ...formErrors, [name]: "" });
    } else if (value.length === 12) {
      setFormErrors({ ...formErrors, [name]: "Invalid aadhar" });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.toUpperCase() });
  };

  const closeModal = () => {
    setShowmodal(false);
    navigate("/details");
  };
  const closeError = () => {
    setShowError(false);
  };

  const uploadDoc = (name) => {
    // console.log(name);
    // console.log("uploading ", name, ' -> ', imageFile[name])
    if (imageFile[name] !== null && imageFile[name].length !== 0) {
      setUploadingState(States.INIT);
      // console.log("PAN IMG ", imageFile);
      uploadImgBytes(imageFile[name], name, (result) => {
        // console.log("uploadDoc : ", result);
        // setImgUrl({...imgUrl, [name] : result})
        if (name === "pan") {
          let name = "panUrl";
          setFormData({ ...formData, [name]: result });
          setFormErrors({ ...formErrors, [name]: "" });
        } else if (name === "aadhar") {
          let name = "aadharUrl";
          setFormData({ ...formData, [name]: result });
          setFormErrors({ ...formErrors, [name]: "" });
        } else {
          let name = "aadharBackUrl";
          setFormData({ ...formData, [name]: result });
          setFormErrors({ ...formErrors, [name]: "" });
        }
        // setFormData({
        //   ...formData,
        //   [name === "pan" ? "panUrl" : "aadharUrl"]: result,
        // });
        // setFormErrors({
        //   ...formErrors,
        //   [name === "pan" ? "panUrl" : "aadharUrl"]: "",
        // });
        setUploadingState(States.SUCCESS);
      });
    } else {
      if (name === "pan") {
        let name = "panUrl";
        setFormErrors({ ...formErrors, [name]: States.INVALIDURL });
      } else if (name === "aadhar") {
        let name = "aadharUrl";
        setFormErrors({ ...formErrors, [name]: States.INVALIDURL });
      } else {
        let name = "aadharBackUrl";
        setFormErrors({ ...formErrors, [name]: States.INVALIDURL });
      }
    }
  };

  const handleSubmitDetaills = () => {
    setUpdateStatus(true);

    const newFormErrors = {
      name: formData.name.length === 0 ? "Enter valid name" : "",
      dob: formData.dob.length === 0 ? "Enter valid date of birth" : "",
      pan: formData.pan.length === 0 ? "Enter valid pan" : "",
      aadhar:
        formData.aadhar === undefined || formData.aadhar.length !== 12
          ? "Enter valid aadhar number"
          : "",
      panUrl: formData.panUrl ? "" : "Please upload pan",
      aadharUrl: formData.aadharUrl ? "" : "Please upload aadhar front image",
      aadharBackUrl: formData.aadharBackUrl
        ? ""
        : "Please upload aadhar back image",
    };

    setFormErrors(newFormErrors);

    if (
      newFormErrors.name.length === 0 &&
      newFormErrors.pan.length === 0 &&
      newFormErrors.dob.length === 0 &&
      newFormErrors.aadhar.length === 0 &&
      newFormErrors.panUrl.length === 0 &&
      newFormErrors.aadharUrl.length === 0 &&
      newFormErrors.aadharBackUrl.length === 0
    ) {
      setUpdateStatus(States.INIT);
      document.body.style.overflowY = "hidden";
      updateKyc(formData, (response) => {
        if (response) {
          setUpdateStatus(null);
          if (dKyc) {
            setIsDisabled(true);
          }
          setShowmodal(true);
          document.body.style.overflowY = "scroll";
          setIsDisabled(true);
        } else {
          setUpdateStatus(null);
          setShowError(true);
          document.body.style.overflowY = "scroll";
        }
      });
    }
  };

  if (!loading && user !== null) {
    return (
      <>
        {updateStatus === States.INIT && (
          <div className="center-screen">
            <Spinner />
          </div>
        )}
        {showError && <Error closeError={closeError} />}
        {showModal && <UpdatedMessageModal closeModal={closeModal} />}
        <div className="kyc-wrapper" id="kycId">
          <div className="kyc-box">
            <div className="kyc-screen">
              <div className="details_heading">
                <p>Please fill your personal details</p>
              </div>

              <div className="item">
                <div className="detail_input_label">
                  <p>Full Name (As Per PAN Card)</p>
                </div>
                <div className="detail_input">
                  <input
                    type="text"
                    name="name"
                    maxLength={25}
                    readOnly={dKyc}
                    onChange={onInputChange}
                    defaultValue={formData.name}
                  />
                  {formErrors.name && (
                    <div className="error-message" id="namingerror">
                      <span className="error-star"></span> {formErrors.name}
                    </div>
                  )}
                </div>
              </div>

              <div className="item">
                <div className="detail_input_label">
                  <p>Date of Birth</p>
                </div>
                <div className="detail_input">
                  <input
                    type="date"
                    name="dob"
                    readOnly={dKyc}
                    onChange={onInputChange}
                    defaultValue={formData.dob}
                  />
                  {formErrors.dob && (
                    <div className="error-message" id="namingerror">
                      <span className="error-star"></span> {formErrors.dob}
                    </div>
                  )}
                </div>
              </div>

              <div className="item">
                <div className="detail_input_label">
                  <p>PAN Number</p>
                </div>
                <div className="detail_input">
                  <input
                    type="text"
                    name="pan"
                    readOnly={dKyc}
                    maxLength={10}
                    onChange={verifyPanChange}
                    defaultValue={formData.pan}
                  />
                  {updateStatus && formErrors.pan && (
                    <div className="error-message">
                      <span className="error-star"></span> {formErrors.pan}
                    </div>
                  )}
                </div>
              </div>

              <div className="item">
                <div className="detail_input_label">
                  <p>Aadhar Number</p>
                </div>
                <div className="detail_input">
                  <input
                    type="tel"
                    name="aadhar"
                    maxLength={12}
                    readOnly={isDisabled}
                    onChange={onInputAadhar}
                    defaultValue={formData.aadhar}
                  />
                  {formErrors.aadhar && (
                    <div className="error-message">
                      <span className="error-star"></span> {formErrors.aadhar}
                    </div>
                  )}
                </div>
              </div>

              <div className="item">
                <div className="detail_input_label">
                  <p>Upload PAN</p>
                  <div className="fileSelector">
                    <input
                      type="file"
                      // readOnly={mKyc}
                      disabled={isDisabled}
                      onChange={(e) => {
                        setImageFile({
                          ...imageFile,
                          ["pan"]: e.target.files[0],
                        });
                      }}
                    />
                    <button
                      disabled={isDisabled}
                      onClick={() => uploadDoc("pan")}
                    >
                      Upload
                    </button>
                  </div>
                </div>
                <br />
                <div>
                  {formData["panUrl"] !== null && (
                    <img
                      src={formData["panUrl"]}
                      height="200px"
                      width="200px"
                    />
                  )}
                </div>

                {formData.panUrl !== null && formData.panUrl !== undefined && (
                  <span
                    style={{
                      display: "block",
                      fontSize: "13px",
                      textAlign: "left",
                    }}
                  >
                    Uploaded successfully
                  </span>
                )}
                {formErrors["panUrl"] === States.INVALIDURL && (
                  <span
                    style={{
                      display: "block",
                      color: "red",
                      textAlign: "left",
                      fontSize: "12px",
                    }}
                  >
                    Please select valid file
                  </span>
                )}
                {uploadingState === States.INIT && (
                  <div className="center-screen">
                    <Spinner />
                  </div>
                )}
                {formErrors.panUrl && (
                  <div className="error-message">
                    <span className="error-star"></span> {formErrors.panUrl}
                  </div>
                )}
              </div>

              <div className="item">
                <div className="detail_input_label">
                  <p>Upload Front Aadhar</p>
                </div>
                <div className="fileSelector">
                  <input
                    type="file"
                    disabled={isDisabled}
                    onChange={(e) =>
                      setImageFile({
                        ...imageFile,
                        ["aadhar"]: e.target.files[0],
                      })
                    }
                  />
                  <button
                    disabled={isDisabled}
                    onClick={() => uploadDoc("aadhar")}
                  >
                    Upload
                  </button>
                </div>
                <br />
                <div>
                  {formData["aadharUrl"] !== null && (
                    <img
                      src={formData["aadharUrl"]}
                      height="200px"
                      width="200px"
                    />
                  )}
                </div>
                {/* {uploadingState === States.INIT && <Spinner />} */}
                {formData.aadharUrl !== null &&
                  formData.aadharUrl !== undefined && (
                    <span
                      style={{
                        display: "block",
                        fontSize: "13px",
                        textAlign: "left",
                      }}
                    >
                      Uploaded successfully
                    </span>
                  )}
                {formErrors["aadharUrl"] === States.INVALIDURL && (
                  <span
                    style={{
                      display: "block",
                      color: "red",
                      textAlign: "left",
                      fontSize: "12px",
                    }}
                  >
                    Please select valid file
                  </span>
                )}

                {formErrors.aadharUrl && (
                  <div className="error-message">
                    <span className="error-star"></span> {formErrors.aadharUrl}
                  </div>
                )}
              </div>

              <div className="item">
                <div className="detail_input_label">
                  <p>Upload Back Aadhar</p>
                </div>
                <div className="fileSelector">
                  <input
                    type="file"
                    disabled={isDisabled}
                    onChange={(e) =>
                      setImageFile({
                        ...imageFile,
                        ["aadharBack"]: e.target.files[0],
                      })
                    }
                  />
                  <button
                    disabled={isDisabled}
                    onClick={() => uploadDoc("aadharBack")}
                  >
                    Upload
                  </button>
                </div>
                <br />
                <div>
                  {formData["aadharBackUrl"] !== null && (
                    <img
                      src={formData["aadharBackUrl"]}
                      height="200px"
                      width="200px"
                    />
                  )}
                </div>
                {/* {uploadingState === States.INIT && <Spinner />} */}
                {formData.aadharBackUrl !== null &&
                  formData.aadharBackUrl !== undefined && (
                    <span
                      style={{
                        display: "block",
                        fontSize: "13px",
                        textAlign: "left",
                      }}
                    >
                      Uploaded successfully
                    </span>
                  )}
                {formErrors["aadharBackUrl"] === States.INVALIDURL && (
                  <span
                    style={{
                      display: "block",
                      color: "red",
                      textAlign: "left",
                      fontSize: "12px",
                    }}
                  >
                    Please select valid file
                  </span>
                )}

                {formErrors.aadharBackUrl && (
                  <div className="error-message">
                    <span className="error-star"></span>{" "}
                    {formErrors.aadharBackUrl}
                  </div>
                )}
              </div>

              <div
                className={`kyc_button ${isDisabled ? "disabled" : ""}`}
                onClick={() => {
                  if (!isDisabled) {
                    handleSubmitDetaills();
                  }
                }}
              >
                <button>UPDATE</button>
                <img src={fbutton} alt="" id="kbutton" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <Spinner></Spinner>;
  }
}

export default KycDetails;
