import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import aryologo from "../images/aryologoblue.png";
import "../css/privacy.css";

function Privacy() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  });

  return (
    <>
      <div className="privacy_container">
        {" "}
        <div>
          <Link to="/" id="logo">
            <img src={aryologo} alt="" className="img-fluid" />
          </Link>
        </div>
        <h1 id="pp">PRIVACY POLICY– M/S GARNAR TECHNOLOGIES PRIVATE LIMITED</h1>
        <h4>1. INTRODUCTION</h4>
        <p>
          This Privacy Policy sets out how the information provided by user is
          collected, used, stored, processed, transferred, and protected by M/S
          GARNAR TECHNOLOGIES PRIVATE LIMITED. Please read the terms of this
          policy carefully. By downloading and registering on our application,
          or by using any of our services, the user agrees to be bound by all
          the terms of this Privacy Policy. <br />
          For the purposes of this Privacy Policy, ‘we’, ‘us’, ‘our’, ‘ARYO’ and
          “Company” refers to M/S GARNAR TECHNOLOGIES PRIVATE LIMITED and ‘you’
          refers to the users of ARYO who download themselves on the ARYO
          application for using it. “ThirdParty” refers to such other companies
          with which M/S GARNAR TECHNOLOGIES PRIVATE LIMITED is engaged in
          business dealings with.,
          <br />
          By downloading or registering on our mobile application- ARYO
          (“Hereinafter referred to as “App”), you authorize us to contact you
          via email or phone call or SMS or WhatsApp or any other electronic
          medium for purposes as set out in the Terms and Conditions. <br />
          By registering on our App, you authorize us to send Messages/SMS/Email
          alerts to you f or your login details or for any other service
          requirements or for some advertising messages/emails from us. By
          registering on our App, you further authorize us to forward the
          information put in by you to Third Parties for marketing and business
          purposes. <br />
          "If you are no longer interested in receiving e-mail announcements and
          other marketing information from us, or you want us to remove any
          information that we have collected about you, please e-mail your
          request to the Data Grievance Officer whose number has been provided
          at the bottom." <br />
          This policy does not apply to people who do not download or register
          themselves on the App. We understand and believe that the information
          provided by you is correct. However, please understand that the
          provisions of the services provided by us will be discontinued to you,
          at our sole discretion, if any information provided by you is untrue,
          inaccurate, out of date or incomplete or subsequently becomes untrue,
          inaccurate, out of date or incomplete, or there are reasonable grounds
          to suspect that the information provided by you is untrue, inaccurate,
          out of date or incomplete.
        </p>
        <h4>2. INFORMATION WE COLLECT</h4>
        <p>We will collect the following information from you: </p>
        <ol type={1}>
          <li>
            Personal Information that will mean and include all kind of
            information which can be linked to a specific individual or to
            identify any individual, directly or indirectly, such as name, age,
            address, contact details (phone numbers/ email address), date of
            birth and a photo/ selfie.
          </li>
          <li>Location of device</li>
          <li>Government identification card like AADHAR/Pan Card</li>
          <li>Financial data including bank account number, IFSC Code. </li>
          <li>Biometric Information, wherever applicable.</li>
          <li>Information provided by the customers to you- the user</li>
        </ol>
        <h4>3. PURPOSE, USE AND STORAGE OF COLLECTED INFORMATION</h4>
        <p>
          The information/data collected (as mentioned above) may be used for
          analytics to ultimately improve our service offerings to you. The
          information/data is also collected for the purposes of providing
          proper services, verification of the identity and usage of information
          in a proper and an effective manner.Information collected will be used
          for the following purposes:{" "}
        </p>
        <ul>
          <li>
            For providing information about various products and services.{" "}
          </li>
          <li>
            For the purpose of facilitating the completion of any process
            floated by third parties and which is requested by your customers to
            you for such facilitation. To facilitate the process, we provide
            your personal information to such third parties only for business/
            marketing purposes and for no other purpose.
          </li>
          <li>
            For processing your transactions and also to provide you transaction
            and post transaction-related services.{" "}
          </li>
          <li>
            For providing, improving, and marketing our products and services,
            including App content and performance.
          </li>
          <li>For sending you survey and marketing communications. </li>
          <li>
            For facilitating various programs and initiatives launched either by
            us or third parties which we believe may be of an interest to you.{" "}
          </li>
          <li>For facilitating usage of our App. </li>
          <li>For improving our services, product, or content on our App.</li>
          <li>For verification purposes</li>
          <li>For biometric information for attendance purposes. </li>
          <li>
            To improve our products and enhance the service provided by ARYO
          </li>
          <li>
            For Aadhaar authentication and sharing, storing, using Aadhaar data.{" "}
          </li>
          <li>For sales and marketing Activities</li>
          <li>Allow you to access specific account information. </li>
          <li>
            All the data/information collected may be stored on the
            infrastructure of third party to whom we supply information filled
            in by you– the user.
          </li>
        </ul>
        <h4>4. INFORMATION SHARING AND DISCLOSURE</h4>
        <p>
          We do not share or disclose the information to any other
          individual/institutions and their subsidiaries/associates/holding
          company/ subsidiaries companies/ group companies/ affiliates, tie-up
          institutions etc. for any purpose unless such a disclosure is
          explicitly authorized by you. We will not share or disburse the
          information that we have collected from you to anyone other than as
          specifically noted herein below:{" "}
        </p>
        <ul>
          <li>
            Where information collected by us is required to be disclosed to
            comply with any applicable law, regulation and legal process or in
            response to law enforcement authority or other governmental official
            request; to detect, prevent, or otherwise address fraud, cyber
            incidents, prosecution, and punishment of offences; for security or
            technical issues; to investigate a complaint or security threat and
            for any public interests.{" "}
          </li>
          <li>
            For the purpose of facilitating the completion of any process
            floated by third parties and which is requested by your customers to
            you for such facilitation. To facilitate the process, we provide
            your personal information to such third parties only for business/
            marketing purposes and for no other purpose.
          </li>
          <li>For any other marketing purpose. </li>
          <li>
            We may share personal information to help us operate our business
            and the site or administer activities such as detection of identity
            theft, fraud and other potentially illegal acts. We may share your
            information with these third parties for those limited purposes
            provided that you have given us your permission.{" "}
          </li>
        </ul>
        <h4>5. RIGHTS UNDER THE POLICY </h4>
        <p>
          The below mentioned rights can be exercised by you as and when we
          obtain or hold personal information about you, or access personal
          information we hold about you, and for obtaining its correction,
          update, amendment, or deletion in appropriate circumstances where
          permitted by applicable law or regulation. Some of these rights may be
          subject to some exceptions or limitations.{" "}
        </p>
        <ul>
          <li>
            You have the right to Access your personal data held about you and
            to learn the origin of the data, the purposes and means of the
            processing, the details of the parties to whom the data may be
            disclosed.
          </li>
          <li>
            Withdraw your consent at any time where your personal data is
            processed with your consent.{" "}
          </li>
          <li>Update and correct your personal data so that it is accurate.</li>
          <li>
            Delete your personal data from our records if it is no longer
            needed.
          </li>
          <li>
            Restrict the processing of your personal data in certain
            circumstances, e.g. where you have contested the accuracy of your
            personal data, for the period enabling us to verify its accuracy.
          </li>
          <li>Obtain your personal data in an electronic format.</li>
          <li>
            File a complaint with us and/or the relevant data grievance officer;
            and{" "}
          </li>
          <li>
            Object to us processing your personal data, or tell us to stop
            processing it (including for purposes of direct marketing, survey
            etc)
          </li>
        </ul>
        <p>
          The above rights can be exercised by you by approaching to the Data
          Grievance Of ficer named at the bottom of this document.
        </p>
        <h4>6. SECURITY MEASURES</h4>
        <p>
          Protection of your information is of paramount importance to us.We
          adopt appropriate data collection, storage and processing practices
          and security measures to protect against any unauthorized access,
          alteration, disclosure or destruction of your information, username,
          password, transaction information and any other information/data
          stored with us. The security measure taken by us is as following:
        </p>
        <ul>
          <li>Data is stored in an encrypted format.</li>
        </ul>
        <p>
          M/S GARNAR TECHNOLOGIES PRIVATE LIMITED cannot beheld liable by you–
          the user for leakage of any inf ormation that may happen due to the
          hacking of the App or any other similar way.{" "}
        </p>
        <h4>7. UPDATE/CORRECTION/DELETION OF INFORMATION</h4>
        <p>
          You have the right to access your personal information. You also have
          the right to request correction and deletion of the information shared
          by you. Changes can be made in system as and when there is any
          requirement for a change in the information. Personal information can
          be corrected, deleted or amended by contacting us. We will make
          efforts in good faith to make requested changes in our then-active
          databases as soon as reasonably possible. Thus, you may contact us
          through the Grievance Officer to have your information
          updated/corrected/deleted with us.
        </p>
        <h4>8. RETENTION PERIOD</h4>
        <p>
          All information collected by us will be stored with us as long as you
          are registered with our App or as long as it is necessary for us to
          fulfill our obligations in respect of the provision of the services
          and in accordance with the applicable laws. <br />
          Information collected by us can also be retained by us if we believe
          it to be necessary to prevent fraud or future abuse; if required by
          law, for other legitimate purposes.
        </p>
        <h4>9. CONSENT</h4>
        <p>
          By using our App and by providing your information, it is assumed that
          you have provided your express consent to the collection and use of
          the information which is disclosed in accordance with this Privacy
          Policy, including but not limited to, your express consent f or
          sharing this information as per this Privacy Policy. We recommend that
          you do not use/access and/or continue to use/access the App if you do
          not agree to the clauses of this Privacy Policy.{" "}
        </p>
        <p>
          You have an option to refuse giving your consent or take back your
          consent in a way as specified below:
        </p>
        <p>User can choose to de- register or delete his account; or</p>
        <ul>
          <li>Contact the Data Grievance Officer. </li>
        </ul>
        <h4>10. COOKIES</h4>
        <p>
          A cookie is a small text file that is stored on a computer for
          record-keeping purposes. We use cookies and other technologies such as
          pixel tags and clear gifs to store certain types of information each
          time you visit our App. Cookies enable to recognize the information
          you have consented to give to our App and help us determine what
          portions of our App are most appropriate for your professional needs.
          We may also use cookies to improve our App performance. <br />
          Whether you want your web browser to accept cookies or not is up to
          you. If you haven't changed your computer's settings, most likely your
          browser already accepts cookies. If you choose to decline cookies, you
          may not be able to fully experience all features of the App. You can
          also delete your browser cookies or disable them entirely. But this
          may significantly impact your experience with our App and may make
          parts of the App non -functional or inaccessible. We recommend that
          you leave them turned on. <br />
          <span>Third Party Cookies are allowed.</span>
        </p>
        <h4>11. ACCEPTANCE OF THESE TERMS</h4>
        <p>
          By using our App, you signify your acceptance of this Privacy Policy.
          If you do not agree to this Privacy policy, please do not download or
          register our App. Your continued use of our App will be deemed to be
          amounting to the acceptance to this Privacy Policy.
        </p>
        <h4>12. DATA GRIEVANCE OFFICER</h4>
        <p>
          Ashish Saini
          <br />
          Contactus@aryo.club
          <br />
          UNIT NO. 129-130, WESTEND MALL, Janakpuri, Janakpuri District Centre,
          New Delhi, West Delhi, Delhi, 110058
        </p>
      </div>
    </>
  );
}

export default Privacy;
