import RouterPath from "./Components/RouterPath";

function App() {

  return (
    <RouterPath />
  );
}

export default App;
