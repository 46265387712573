import React, { useEffect, useState } from "react";
import { auth, signInWithCustomToken } from "../Firebase";
import { getFirebaseToken } from "../Connection";
import VerifyOtp from "./VerifyOtp";
import * as States from "./States";
import "../css/login.css";
import EnterMobile from "./EnterMobile";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";


function Login() {

  const navigator = useNavigate();
  const [user, setUser] = useState(null);
  const [userState, setUserState] = useState(null);
  const [mobile, setMobile] = useState(null);

  useEffect(() => {
// console.log(mobile)

    if (user === null && userState === null) {
      setUserState(States.INIT);
    } else if (user === null && userState === States.OTP_VERIFIED) {
      //get token
      getFirebaseToken(mobile, (token) => {
        signIn(token);
      })
    } else if (user !== null) {
      // console.log('user', user);
      //is new user 
      localStorage.setItem('mobile', '+91' + mobile);
      if (user.email === null) {
        navigator("/details/profile");
      } else {
        // console.log('loaded')
        navigator("/details");
      }
    }
  }, [userState])

  useEffect(() => {
    if (mobile !== null && userState === States.INIT)
    {
      setUserState(States.INIT_OTP);
    }
  }, [mobile])

  

  if (userState === States.INIT) {
    return(<EnterMobile setMobile={setMobile}/>);
  } else if (userState === States.INIT_OTP) {
    return(<VerifyOtp mobile={mobile} setUserState={setUserState}/>)
  }
  else{
    return(
      <>
      <Spinner/>
      </>
    )
  }

  function signIn(token) {

    let promise = signInWithCustomToken(auth, token);
    promise.then(user => {
      // console.log('signed in user ', user.user)
      setUser(user.user);
      setUserState(States.LOGGEDIN);
    }).catch(err => {
      console.log(err.code);
    })
  }
  
}

export default Login;
