import React from "react";
import "../css/personnel.css";
import freelancer from "../images/freelancer_dsa_agent.png";
import creditcard from "../images/credit_card_dsa.png";
import insurance from "../images/insurance_agent_dsa_new.png";
import loan from "../images/loan_agent_new.png";
import retired from "../images/retired_dsa_agent.png";

function Personnel() {
  return (
    <div className="personnel-wrapper">
      <p id="personnel-heading">
        Who all can <span id="p1">become ARYO DSA?</span>
      </p>
      <div className="personnel-container">
        <div className="personnel-logo">
          <img src={freelancer} alt="" />
          <p>Freelancers</p>
          <p>
            Looking to earn extra? 
            Get free training and promising career in selling Financial Products
          </p>
        </div>
        <div className="personnel-logo">
          <img src={creditcard} alt="" />
          <p>Credit Card Agencies</p>

          <p>
            Discover path to earning extra with ease! 
            Your experience and our expertise can be complemented for mutual benefit
          </p>
        </div>
        <div className="personnel-logo">
          <img src={insurance} alt="" />
          <p>Insurence Agencies</p>

          <p>
            Explore lucrative opportunities to boost your income! 
            Access free training and embark on a promising career in finance.
          </p>
        </div>
        <div className="personnel-logo">
          <img src={loan} alt="" />
          <p>Loan Agencies</p>
          <p>
            Unlock additional financial opportunities! 
            Acquire complimentary training for a prosperous career in finance.
          </p>
        </div>
        <div className="personnel-logo retired">
          <img src={retired} alt="" />
          <p>Retired Personnel</p>
          <p>
            Retired and ready for a new chapter?  Earn from home with free
            finance training!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Personnel;
