import React, { useEffect } from "react";
import Header from "./Header";
import ContactUs from "./ContactUs";
import Partners from "./Partners";
import Personnel from "./Personnel";
import Products from './Products';
import Benefits from "./Benefits";
import Aos from "aos";
import "aos/dist/aos.css";

function ContactPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  });

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <div>
        <div className="contact-screen">
          <div className="contact_header_container">
            <Header />
          </div>
          <ContactUs />
        </div>
        <Personnel />
        <Products/>
        <Benefits />
        <Partners />
      </div>
    </>
  );
}

export default ContactPage;
