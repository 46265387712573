import React from "react";
import man from "../images/success_man.png";
import people from "../images/success_people3.png";
import medal from "../images/medal2.png";
import "../css/appAchievement.css";

function AppAchievement() {
  return (
    <div className="apss-wrapper">
      <div className="app-ss one">
        <div className="app-info">
          <img src={people} alt="" id="moneyplant-img" />
        </div>
      </div>
      <div className="app-info-box">
        <p className="app-info-inner">
          1 Lac+ Advisors <br />
          currently use <span id="aryo-name">ARYO</span> App
        </p>
        <br />
        <img src={medal} alt="" id="medal2" data-aos="flip-left" />
        <br />
        <p className="app-info-inner">
          Ever Increasing Persons <br />
          Joining Aryo Platform & <br />
          Earning Money
        </p>
      </div>
      <div className="app-ss two">
        <div className="app-info">
          <img src={man} alt="" id="man-img" />
        </div>
      </div>
    </div>
  );
}

export default AppAchievement;
