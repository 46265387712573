import React from "react";
import cc from "../images/credit_card_new.png";
import demat from "../images/demat_new.png";
import saving from "../images/savings_new.png";
import loan from "../images/loan_11.png";
import bnpl from '../images/bnpl.png'
import "../css/product.css";

function Products() {
  return (
    <div className="product-wrapper">
      <p id="product-heading"><span className="blue">Multiple </span> Categories to <span className="blue">Sell</span>  from!</p>
      <div className="product-container">
        <div className="product" data-aos="fade-up">
          <div className="product-image">
            <img src={cc} alt="" />
          </div>
          <div className="product-info" >
            <p className="product-heading">
             Credit Cards
            </p>
            <div className="product-detail">
              <p>Sell credit with features such as Lifetime free cards, Exciting rewards & cashbacks, Complimentary airport lounge access & more!</p>
            </div>
          </div>
        </div>
        <div className="product" data-aos="fade-up" >
          <div className="product-image">
            <img src={saving} alt="" />
          </div>
          <div className="product-info">
            <p className="product-heading">
            Savings Account
            </p>
            <div className="product-detail">
              <p>Open 100% Digital Savings accounts with trusted banks and enjoy features such as Zero balance accounts, best interest rates and much more!</p>
            </div>
          </div>
        </div>
        <div className="product" data-aos="fade-up" >
          <div className="product-image">
            <img src={demat} alt="" />
          </div>
          <div className="product-info">
            <p className="product-heading">
             Demat Account
            </p>
            <div className="product-detail">
              <p>Sell Demat Accounts from banks and reputed companies, easy & 100% digital account opening process with great payouts</p>
            </div>
          </div>
        </div>
        <div className="product" data-aos="fade-up" >
          <div className="product-image">
            <img src={loan} alt="" />
          </div>
          <div className="product-info">
            <p className="product-heading">
            Loan
            </p>
            <div className="product-detail">
              <p>Loans for any purpose - personal or business from top private or government banks, low interest rates. Refer and get best commission of every referral
             
              </p>
            </div>
          </div>
        </div>
        <div className="product bnpl" data-aos="fade-up">
          <div className="product-image">
            <img src={bnpl} alt="" />
          </div>
          <div className="product-info">
            <p className="product-heading">
             Buy Now Pay Later
            </p>
            <div className="product-detail">
              <p>Many brands to choose from, lumpsum or easy installment payback options, nil interest rate options</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
