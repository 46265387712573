import { useState, useEffect } from "react";
import "../css/image-slider.css";

export function ImageSlider({ images }) {
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex((index) => (index === images.length - 1 ? 0 : index + 1));
    }, 3500);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="slider-container">
      <div
        className="slider-content"
        style={{ transform: `translateY(${-imageIndex * 400}px)` }}
      >
        {images.map((imageUrl, index) => (
          <img
            key={index}
            src={imageUrl}
            alt={`Slide ${index + 1}`}
            className="img-slider-img"
          />
        ))}
      </div>
    </div>
  );
}
