import React , {useEffect} from 'react'
import '../css/MyModal.css'
import error from "../images/error_logo.png";

function Error({closeError}) {

  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
          document.body.style.overflowY = "scroll";
    };
  } ,[])

  return (
    <div className='error-wrapper'>
    <div className='error-container'>

       <img src={error} alt="" className='error' />
       <p id="modal_error">Error</p>
       <p className='error_message'>Something went wrong!</p>
       <button className='errorButton' onClick={closeError} >Try Again</button>
    </div>
    
  </div>
  )
}

export default Error
